import moment from "moment";
import {normalizeText} from "./TextHelper";

export default class ListSortHelper {
  static sortASC(list: any[], property: string): any[] {
    return list.sort((a, b) =>
      a[property] > b[property] ? 1 : b[property] > a[property] ? -1 : 0
    );
  }
  static sortDESC(list: any[], property: string): any[] {
    return list.sort((a, b) =>
      a[property] < b[property] ? 1 : b[property] < a[property] ? -1 : 0
    );
  }
  static sort(list: any[], property: string, direction: string): any[] {
    return direction === "asc"
      ? this.sortASC(list, property)
      : this.sortDESC(list, property);
  }
  static sortMulti(
    list: any[],
    properties: string[],
    directions: string[]
  ): any[] {
    return list.sort((a, b) => this.compareMulti(a, b, properties, directions));
  }

  static compareMulti(
    a: any,
    b: any,
    properties: string[],
    directions: string[]
  ) {
    let res: number = 0;
    let iProp: number = 0;
    while (res === 0 && iProp < properties.length) {
      let property = properties[iProp];
      res = a[property] < b[property] ? 1 : b[property] < a[property] ? -1 : 0;
      if (directions[iProp] === "asc") res *= -1;
      ++iProp;
    }
    return res;
  }

  static sortFunt(a: any, b: any, order: string) {
    if (order === "asc") return a > b ? 1 : b > a ? -1 : 0;
    else return a < b ? 1 : b < a ? -1 : 0;
  }

  static sortFuntInt(a: any, b: any, order: string) {
    
    if (a === "" || a === null) a = 0;
    if (b === "" || b === null) b = 0;

    if (order === "asc") return parseInt(a) - parseInt(b);
    else return parseInt(b) - parseInt(a);
  }

  static sortFuntNumber(a: any, b: any, order: string) {
    
    if (a === "" || a === null) a = Number.MIN_SAFE_INTEGER;
    if (b === "" || b === null) b = Number.MIN_SAFE_INTEGER;

    if (order === "asc") return Number(a) - Number(b);
    else return Number(b) - Number(a);
  }

  static sortFuntParcelle(a: any, b: any, order: string) {
    if (isNaN(a) && isNaN(b)) {
      return ListSortHelper.sortFunt(a, b, order);
    }

    if (isNaN(a) || isNaN(b)) {
      if (order === "asc") {
        return !isNaN(a) ? -1 : 1;
      } else {
        return !isNaN(a) ? 1 : -1;
      }
    }

    return ListSortHelper.sortFuntInt(a, b, order);
  }

  static sortFuntIntUG(a: any, b: any, order: string) {
    function _parse(s: any) {
      let arr: any[] = s.split('_');
      if (arr.length === 1) {
        arr[0] = 0;
        arr.push('');
      }
      return arr;
    }
    function _compare(arr1: any[], arr2: any[]) {
      if (arr1[0] === arr2[0] && arr1[1] === arr2[1]) {
        return 0;
      }

      if (arr1[0] === arr2[0]) {
        return arr1[1] < arr2[1] ? -1 : 1;
      }
      return parseInt(arr1[0]) - parseInt(arr2[0]);
    }

    let splitA: any[] = _parse(a);
    let splitB: any[] = _parse(b);
    return order === "asc" ? _compare(splitA, splitB) : _compare(splitB, splitA);
  }

  static sortFuntFloat(a: any, b: any, order: string) {

    if (a === "" || a === null) a = 0;
    if (b === "" || b === null) b = 0;

    if (order === "asc")
      return parseFloat(a.replace(",", ".")) - parseFloat(b.replace(",", "."));
    else
      return parseFloat(b.replace(",", ".")) - parseFloat(a.replace(",", "."));
  }

  static sortFuntDate(a: any, b: any, order: string) {
    if (a === "" || a === null) a = '01/01/1900';
    if (b === "" || b === null) b = '01/01/1900';

    let dateFormat = 'DD/MM/YYYY';
    let date1 = moment(a, dateFormat);
    let date2 = moment(b, dateFormat);

    if (date1 == date2) {
      return 0;
    }

    if (order === "asc")
      return date1 < date2 ? -1 : 1;
    else {
      return date2 < date1 ? -1 : 1;
    }
  }

  static sortNormalize(a: any, b: any) {
    if (normalizeText(a.toLowerCase()) > normalizeText(b.toLowerCase()))
      return 1;
    return normalizeText(a.toLowerCase()) < normalizeText(b.toLowerCase())
      ? -1
      : 0;
  }
}
