export enum RolePortail {
  ADMIN = "ADMIN",
  RSP = "RSP",
  COM = "COM",
  STD = "STD",
  RESP_REPR = "RESP_REPR",
  AGENT_REPR = "AGENT_REPR",
  AGENT_COM_REPR = "AGENT_COM_REPR",
}

export enum UserType {
  INT = "INT",
  EXT = "EXT",
}

export enum TFTInfo {
  POSTE_VACANT = "Poste vacant",
  INFO_NON_DISPO = "Information non disponible"
}

export enum MessageType {
  ALERTE = "ALERTE",
  INFO = "INFO"
}

export enum MessageStatus {
  AVEC_ACCEPTATION = "AVEC_ACCEPTATION",
  SANS_ACCEPTATION = "SANS_ACCEPTATION",
}

export enum EGroupLayerID {
  CONTOURS_BIODIVERSITE = "CONTOURS_BIODIVERSITE",
  CONTOURS_FORESTITIERS = "CONTOURS_FORESTITIERS",
  POINTS_RENCONTRE_SECOURS_FORET = "POINTS_RENCONTRE_SECOURS_FORET"
}

export enum InfoGeneralForet {
  AMENAGEMENT = "Aménagement",
  SUPERFICIES_FORESTIERES_COMMUNE = "Surfaces forestières publiques sur le ban communal",
  CERTIFICATION_PEFC = "Certification PEFC",
  NATURA_2000_ZSC = "NATURA 2000/ZSC",
  NATURA_2000_ZPS = "NATURA 2000/ZPS",
  SITE_CLASSE = "Site classé",
  ZNIEFF_TYPE_I = "ZNIEFF type I",
  ZNIEFF_TYPE_II = "ZNIEFF type II",
  APB = "APB",
  RESERVE_BIO = "Réserves biologiques",
  PARC_NAT = "Parc national",
  PNR = "Parc naturel régional (PNR)",
  SURFACE_EN_DESEQUILIBRE_FAUNE_FLORE = "Surface en déséquilibre faune/flore",
  PASTORALISME = "Surface avec enjeu(x) de pastoralisme",
  INCENDIE = "Surface avec enjeu(x) incendie",
  SURFACE_AMENAGEE = "Surface aménagée",
}

export enum MessageLabels {
  TITRE = "titre",
  DESCRIPTION = "description",
  STATUS = "statut",
  TEXT_STATUS = "texteStatut",
  DATE_DEBUT = "dateDebut",
  DATE_FIN = "dateFin",
  HEURE_DEBUT = "heureDebut",
  HEURE_FIN = "heureFin",
  AVEC_COCHE = "avecCoche",
  TYPE = "type",
}

export enum MessageParamModal {
  ACCEPTATION_VALUE = "acceptationValue",
  DESACTIVATION_VALUE = "desactivationValue"
}

export enum Dashboard {
  TFT = "tff",
  COUPES = "coupes",
  INFO_GENERAL = "infoGeneral",
  MESSAGE = "message",
  CONTACT_PRIVILEGIE = "contactPrivilegie",
  RECETTES = "recettes",
}

export enum UtilisateurAInscrireStatus {
  NON_CONTACTE = "Non contacté",
  INVITATION_ENVOYEE = "Invitation à s'inscrire envoyée",
  INSCRIT = "Inscrit",
  NE_SOUHAITE_PAS_INSCRIRE = "Ne souhaite pas s’inscrire",
  TOUS_STATUT = "Tous statuts contactés",
}

export enum ApiStatus {
  UNINITIATED = 0,
  LOADING = 1,
  SUCCESS = 2,
  ERROR = 3,
}